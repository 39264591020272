.container {
  width: inherit;
  z-index: 2;
  height: 100%;
  display: flex;
  color: inherit;
  cursor: pointer;
  overflow: hidden;
  transition: 0.15s ease;
  flex-direction: column;
  box-sizing: border-box;
  background-color: inherit;
  border-radius: var(--border-radius);
  border: 1px solid rgba(100, 100, 100, 0.2);

  .anchor-level {
    width: 100%;
    height: 100%;
    z-index: 1;
    position: absolute;
  }

  figure {
    width: 100%;
    height: auto;
    overflow: hidden;
    user-select: none;
    aspect-ratio: 16/9;
    margin-block-end: 0;
    margin-inline-end: 0;
    margin-block-start: 0;
    margin-inline-start: 0;

    &[aria-label="restricted"]::after {
      content: attr(data-content);
      position: absolute;
      top: 0;
      right: 0;
      white-space: nowrap;
      padding: 10px 40px;
      color: var(--white); /* Choose your desired text color */
      font-size: var(--fs-md);
      font-weight: var(--fw-medium);
      background-color: #ff9800; /* Choose your desired background color */
      transform: translate(25%, 40%) rotate(45deg);
      box-shadow: 2px 1px 30px 2px rgb(0, 0, 0);
    }
    &[aria-label="bundle"]::after {
      content: attr(data-content);
      position: absolute;
      top: 0;
      right: 0;
      white-space: nowrap;
      padding: 10px 40px;
      color: var(--white); /* Choose your desired text color */
      font-size: var(--fs-md);
      font-weight: var(--fw-medium);
      background-color: #27377e; /* Choose your desired background color */
      transform: translate(25%, 40%) rotate(45deg);
      box-shadow: 2px 1px 30px 2px rgb(0, 0, 0);
    }
    &[aria-label="online"]::after {
      content: attr(data-content);
      position: absolute;
      top: 0;
      right: 0;
      white-space: nowrap;
      padding: 10px 40px;
      color: var(--white); /* Choose your desired text color */
      font-size: var(--fs-md);
      font-weight: var(--fw-medium);
      background-color: #afb837; /* Choose your desired background color */
      transform: translate(25%, 40%) rotate(45deg);
      box-shadow: 2px 1px 30px 2px rgb(0, 0, 0);
    }
    &[aria-label="offline"]::after {
      content: attr(data-content);
      position: absolute;
      top: 0;
      right: 0;
      white-space: nowrap;
      padding: 10px 40px;
      color: var(--white); /* Choose your desired text color */
      font-size: var(--fs-md);
      font-weight: var(--fw-medium);
      background-color: #399432; /* Choose your desired background color */
      transform: translate(25%, 40%) rotate(45deg);
      box-shadow: 2px 1px 30px 2px rgb(0, 0, 0);
    }

    &[aria-label="unlisted"]::after {
      content: "Unlisted";
      position: absolute;
      top: 0;
      right: 0;
      color: var(--white);
      padding: 10px 45px;
      white-space: nowrap;
      font-size: var(--fs-md);
      font-weight: var(--fw-medium);
      background-color: rgba(128, 128, 128, 1);
      transform: translate(25%, 40%) rotate(45deg);
      box-shadow: 2px 1px 30px 2px rgb(0, 0, 0);
    }

    &[aria-label="free"]::after {
      content: "Free";
      position: absolute;
      top: 0;
      right: 0;
      color: var(--white);
      padding: 10px 45px;
      white-space: nowrap;
      font-size: var(--fs-md);
      font-weight: var(--fw-medium);
      background-color: #9100eb;
      transform: translate(25%, 40%) rotate(45deg);
      box-shadow: 2px 1px 30px 2px rgb(0, 0, 0);
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }

    .buttons {
      display: flex;
      gap: 8px;
      top: 8px;
      right: 8px;
      position: absolute;

      @media screen and (min-width: 500px) {
        top: 10px;
        left: 10px;
        right: 0px;
      }

      svg {
        z-index: 2;
        width: 28px;
        height: 28px;
        padding: 8px;
        border-radius: 3rem;
        transition: 0.15s ease;
        background-color: var(--white);
        color: rgba(0, 0, 0, 0.95);
        box-shadow: var(--card-shadow-hover);

        &:hover {
          color: var(--brand-color);
        }

        @media screen and (min-width: 500px) {
          width: 35px;
          height: 35px;
        }
      }
    }
  }

  summary {
    display: flex;
    flex: 1;
    padding: 10px;
    transition: 0.15s ease;
    flex-direction: column;

    .tags {
      display: flex;
      gap: 3px;
      opacity: 0.85;
      color: inherit;
      flex-wrap: wrap;
      align-items: center;
      margin: -2px 0 5px -10px;

      * {
        line-height: 1;
        font-size: var(--fs-xs);
      }

      *:not(.tag) {
        display: none;
      }

      > span {
        display: inline-block;
        padding: 4px 8px;
        line-height: 1.25;
        padding-left: 12px;
        color: var(--brand-color);
        background-color: var(--brand-color-shade);
        border-top-right-radius: var(--border-radius);
        border-bottom-right-radius: var(--border-radius);
      }

      @media screen and (min-width: 500px) {
        margin: -2px 0 5px -13px;

        *:not(.tag) {
          display: inline-block;
        }
      }

      @media screen and (min-width: 700px) {
        margin: -2px 0 5px -1rem;

        > * {
          font-size: var(--fs-xs);
        }

        .tag {
          padding-left: 1rem;
        }
      }
    }

    h2 {
      margin: 0;
      height: 22px;
      color: inherit;
      display: block;
      max-height: 22px;
      overflow: hidden;
      white-space: nowrap;
      margin-bottom: 5px;
      text-transform: none;
      text-overflow: ellipsis;
      font-size: var(--fs-sm);
      font-weight: var(--fw-bold);

      @media screen and (min-width: 500px) {
        font-size: var(--fs-sm);
        display: -webkit-box;
        white-space: unset;
      }

      @media screen and (min-width: 700px) {
        line-clamp: 2;
        height: 40px;
        max-height: 40px;
        -webkit-line-clamp: 2;
        font-size: var(--fs-md);
      }
    }

    .meta {
      margin-top: auto;
      margin-bottom: 3px;

      p {
        margin: 0;
        opacity: 0.85;
        line-clamp: 1;
        line-height: 1.5;
        color: inherit;
        overflow: hidden;
        display: -moz-box;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        font-size: var(--fs-xxs);
        -webkit-box-orient: vertical;

        @media screen and (min-width: 500px) {
          font-size: var(--fs-xs);
        }

        @media screen and (min-width: 700px) {
          font-size: var(--fs-sm);
        }
      }

      p span {
        color: inherit;
        font-size: inherit;
        line-height: inherit;
        font-weight: var(--fw-medium);
      }
    }

    p {
      margin: 0;
      opacity: 0.85;
      color: inherit;
      line-height: 1.5;
      font-size: var(--fs-xs);

      @media screen and (min-width: 500px) {
        font-size: var(--fs-sm);
      }

      @media screen and (min-width: 700px) {
        font-size: var(--fs-sm);
      }
    }

    .description {
      margin: auto 0 5px;
      padding-top: 3px;
      line-height: 1.5;
      color: inherit;
      opacity: 0.85;
      display: -webkit-box;
      line-clamp: 2;
      -webkit-box-orient: vertical;
      -moz-box-orient: vertical;
      font-size: var(--fs-xxs);
      -webkit-line-clamp: 2;
      overflow: hidden;
      text-overflow: ellipsis;

      @media screen and (min-width: 500px) {
        font-size: var(--fs-xs);
      }

      @media screen and (min-width: 700px) {
        line-clamp: 2;
        font-size: var(--fs-sm);
        -webkit-line-clamp: 2;
      }
    }

    footer {
      width: 100%;
      gap: 10px;
      z-index: 2;
      display: flex;
      margin-top: 3px;
      align-items: center;

      > img {
        height: 25px;
        width: 25px;
        border-radius: 3rem;
      }

      > .info {
        flex: 1;
        > h3 {
          margin: 0;
          line-clamp: 1;
          color: inherit;
          overflow: hidden;
          display: -moz-box;
          line-height: 1.25;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          font-size: var(--fs-sm);
          -moz-box-orient: vertical;
          -webkit-box-orient: vertical;
          font-weight: var(--fw-medium);
        }

        > p {
          opacity: 0.85;
          color: inherit;
          margin: 2px 0 0;
          line-height: 1.25;
          font-size: var(--fs-xxs);
        }
      }

      @media screen and (min-width: 500px) {
        margin-top: 5px;

        > span {
          img {
            height: 30px;
            width: 30px;
          }
        }

        > .info {
          font-size: var(--fs-xxs);
        }
      }

      @media screen and (min-width: 700px) {
        margin-top: 7px;

        > span {
          img {
            height: 35px;
            width: 35px;
          }
        }

        > .info {
          > h3 {
            font-size: var(--fs-md);
          }

          > p {
            font-size: var(--fs-xs);
          }
        }
      }
    }

    .progress {
      width: 100%;

      .pgline {
        width: 100%;
        height: 5px;
        margin: 7px 0 0;
        border-radius: 1rem;
        background-color: var(--brand-color-shade);
        box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);

        > span {
          display: block;
          height: 100%;
          border-radius: 1rem;
          transition: 0.15s ease;
          background-color: var(--brand-color);
          box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.35);
        }

        @media screen and (min-width: 500px) {
          margin: 10px 0 0;
          height: 6px;
        }

        @media screen and (min-width: 700px) {
          margin: 14px 0 0;
          height: 7px;
        }
      }

      .pgtext {
        line-height: 1;
        margin: 6px 0 0;
        color: inherit;
        opacity: 0.85;
        font-size: var(--fs-xxs);

        @media screen and (min-width: 500px) {
          line-height: 1;
          margin: 9px 0 0;
          color: inherit;
          opacity: 0.85;
          font-size: var(--fs-xxs);
        }

        @media screen and (min-width: 700px) {
          line-height: 1;
          margin: 11px 0 0;
          color: inherit;
          opacity: 0.85;
          font-size: var(--fs-xs);
        }
      }
    }

    .mycourse-meta {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      flex-wrap: wrap;
      margin-top: 5px;

      p {
        margin: 0;
        padding: 0;
        opacity: 0.85;
        color: inherit;
        line-height: 1.25;
        font-size: var(--fs-xxs);
        font-weight: var(--fw-medium);

        @media screen and (min-width: 700px) {
          font-size: var(--fs-sm);
        }
      }

      p span {
        font-weight: var(--fw-bold);
      }
    }

    @media screen and (min-width: 700px) {
      padding: 1rem;
    }
  }
}
