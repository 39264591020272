.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 65vw !important;
  height: 76vh !important;

  .contentContainer {
    width: 100%;
    height: 100%;
    display: flex;
    padding: var(--gap-lg);
    padding-bottom: 0px;
    flex-direction: column;

    .warning-container {
      width: 100%;
      height: 100%;
      display: flex;
      gap: var(--gap-lg);
      align-items: flex-start;

      .img-container {
        width: 50%;
        display: flex;
        height: fit-content;
        padding-bottom: 0px;
        align-items: flex-start;
        justify-content: center;
        background-size: contain;
        background-position: bottom;
        background-repeat: no-repeat;
        border-right: 1px solid var(--border-color);

        img {
          height: 90%;
          width: 100%;
          display: flex;
          align-items: center;
          object-fit: contain;
          justify-content: center;
        }
      }

      .right {
        width: 50%;
        height: 100%;
        display: flex;
        gap: var(--gap-md);
        flex-direction: column;
        padding: var(--gap-md) var(--gap-lg);

        > h3 {
          font-size: var(--fs-xl);
          color: var(--text-color);
          margin-bottom: var(--gap-md);
        }

        ul {
          display: flex;
          gap: var(--gap-md);
          flex-direction: column;
          align-items: flex-start;
          padding-left: var(--gap-lg);

          > li {
            line-height: 1.6;
            position: relative;
            font-size: var(--fs-lg);
            font-weight: var(--fs-md);
            color: var(--text-secondary);
          }
        }

        > p {
          font-size: var(--fs-lg);
          margin-top: var(--fs-md);
          font-weight: var(--fw-bold);
        }

        .btn-div {
          display: flex;
          align-items: center;
          justify-content: flex-end;

          > button {
            margin-top: 30%;
          }
        }
      }
    }

    .first-screen {
      width: 100%;
      height: 100%;
      display: flex;
      gap: var(--gap-lg);

      .img-container {
        width: 40%;
        display: flex;
        align-items: center;
        padding-bottom: 0px;
        justify-content: center;
        height: 100% !important;
        background-size: contain;
        background-position: bottom;
        background-repeat: no-repeat;
        border-right: 1px solid var(--border-color);

        img {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          object-fit: contain;
          justify-content: center;
        }
      }

      .sub-container {
        width: 60%;
        display: flex;
        gap: var(--gap-lg);
        flex-direction: column;

        .test {
          display: flex;
          gap: var(--gap-sm);
          flex-direction: column;
          color: var(--text-color);
        }

        h2 {
          line-clamp: 2;
          text-transform: none;
          -webkit-line-clamp: 2;
          font-size: var(--fs-lg);

          @media screen and (min-width: 1500px) {
            font-size: var(--fs-xl);
          }
        }

        .description {
          font-size: var(--fs-md);
          color: var(--text-secondary);

          @media screen and (min-width: 1500px) {
            font-size: var(--fs-lg);
          }

          > span {
            cursor: pointer;
          }
        }

        .test-details {
          p {
            font-weight: var(--fs-md);
            color: var(--text-secondary);
            @media screen and (min-width: 1500px) {
              font-size: var(--fs-lg);
            }
          }
        }

        .about-platform {
          display: flex;
          gap: var(--gap-sm);
          flex-direction: column;
          color: var(--text-color);

          .title {
            text-transform: none;
          }

          ul {
            display: flex;
            padding-left: 15px;
            gap: var(--gap-sm);
            flex-direction: column;
            align-items: flex-start;

            li {
              line-height: 1.5;
              font-weight: var(--fs-md);
              color: var(--text-secondary);
              @media screen and (min-width: 1500px) {
                font-size: var(--fs-lg);
              }
            }
          }
        }
      }
    }

    .second-screen {
      width: 100%;
      display: flex;
      gap: var(--gap-lg);

      .img-container {
        width: 50%;
        display: flex;
        align-items: center;
        padding-bottom: 0px;
        justify-content: center;
        height: 100% !important;
        background-size: contain;
        background-position: bottom;
        background-repeat: no-repeat;
        border-right: 1px solid var(--border-color);

        img {
          width: 100%;
          height: 100%;
          display: flex;
          object-fit: contain;
          align-items: center;
          justify-content: center;
        }
      }

      .second-container {
        width: 50%;
        display: flex;
        gap: var(--gap-xl);
        flex-direction: column;
        color: var(--text-color);

        .contents {
          display: flex;
          gap: var(--gap-md);
          flex-direction: column;
          color: var(--text-color);
          padding: 0px;

          .title {
            line-clamp: 2;
            text-transform: none;
            -webkit-line-clamp: 2;
            font-size: var(--fs-lg);

            @media screen and (min-width: 1500px) {
              font-size: var(--fs-xl);
            }
          }

          .eligibility {
            display: flex;
            gap: var(--gap-sm);
            align-items: center;

            .approvalText {
              color: var(--green);
            }

            .icon {
              width: 20px;
              height: 20px;
              color: red;
            }
            .icon {
              width: 20px;
              height: 20px;
              color: var(--text-color);
            }
            p {
              font-size: var(--fs-md);
              font-weight: var(--fw-medium);
              color: var(--text-secondary);
              @media screen and (min-width: 1500px) {
                font-size: var(--fs-lg);
              }
            }
            .checked {
              // width: 20px;
              // height: 20px;
              color: var(--success-color);
              path {
                stroke: 2px solid black;
              }
            }
            .corssed {
              // width: 20px;
              // height: 20px;
              color: red;
              path {
                stroke: 2px solid black;
              }
            }
          }
        }
      }
      .about-platform {
        display: flex;
        gap: var(--gap-md);
        flex-direction: column;
        color: var(--text-color);

        .platform-title {
          line-clamp: 2;
          -webkit-line-clamp: 2;
          font-size: var(--fs-lg);
          @media screen and (min-width: 1500px) {
            font-size: var(--fs-xl);
          }
        }

        ul {
          display: flex;
          gap: var(--gap-md);
          padding-left: 15px;
          flex-direction: column;
          align-items: flex-start;
          color: var(--text-secondary);

          li {
            font-size: var(--fs-sm);
            @media screen and (min-width: 1500px) {
              font-size: var(--fs-md);
            }
          }
        }
      }
    }
  }

  .footer {
    width: 100%;
    display: flex;
    gap: var(--gap-md);
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-bottom: var(--gap-md);

    .progress-container {
      height: 1%;
      width: 100%;
      background-color: transparent;

      .progressbar {
        height: 2px;
        background-color: var(--brand-color);
        transition: all 0.2s ease-in-out;
      }
    }

    .btn-container {
      display: flex;
      gap: var(--gap-md);
      align-self: flex-end;
      padding: 0px var(--gap-lg);

      .closeBtn {
        display: flex;
        padding: 8px 12px;
        align-self: flex-end;
        color: var(--background);
        background-color: var(--text-color);
      }

      .prev-btn {
        display: flex;
        padding: 8px 12px;
        align-self: flex-end;
        color: var(--background);
        background-color: var(--text-color);
      }

      .btn {
        display: flex;
        padding: 8px 18px;
        align-self: flex-end;
      }
    }
  }
}
