.container {
  display: flex;
  height: auto;
  width: auto;
  top: 0;
  z-index: 1;
  position: sticky;
  flex-direction: column;

  .header {
    width: 100%;
    padding: 10px;
    color: var(--text-color2);
    background-color: var(--second-white);
    h3 {
      text-transform: none;
      font-weight: var(--fw-medium);
      font-size: var(--fs-sm);
    }
  }

  // @media screen and (max-width: 941px) {
  //   display: none;
  // }

  ul {
    display: grid;
    gap: 5px;
    width: auto;
    margin: 10px 0;
    overflow: hidden;
    padding: 0 15px 0 10px;
    grid-template-columns: repeat(2, 1fr);

    li {
      padding: 10px;
      cursor: pointer;
      list-style: none;
      text-align: center;
      border: var(--border);
      font-size: var(--fs-sm);
      color: var(--text-color);
      font-weight: var(--fw-medium);
      border-radius: var(--border-radius);
      background-color: var(--background-secondary);
    }

    .active {
      color: var(--background);
      transition: 0.2s ease-in;
      background-color: var(--text-color);
    }
  }
}
