.container {
  display: flex;
  gap: var(--gap-lg);
  padding: var(--gap-lg);
  flex-direction: column;
  // justify-content: center;

  > img {
    width: auto;
    height: 100%;
    object-fit: cover;
    border-radius: var(--border-radius);

    @media screen and (min-width: 641px) {
      width: auto;
    }
  }

  > h1 {
    text-align: center;
    color: var(--text-color);
    font-size: var(--fs-2xl);

    @media screen and (min-width: 641px) {
      font-size: var(--fs-3xl);
    }

    > span {
      text-transform: none;
      color: var(--brand-color);
    }
  }

  > p {
    text-align: center;
    color: var(--text-color);
    font-size: var(--fs-lg);

    @media screen and (min-width: 641px) {
      font-size: var(--fs-xl);
    }

    > span {
      color: var(--brand-color);
      font-weight: var(--fw-medium);
    }
  }

  > button {
    margin: 0 auto;
    max-width: 140px;
    font-size: var(--fs-sm);

    @media screen and (min-width: 641px) {
      max-width: 220px;
      font-size: var(--fs-md);
    }
  }

  .CoursesSwiper {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}
