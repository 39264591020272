.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  // height: 40vh !important;
  width: 70vw !important;
  padding: 5px 10px;

  @media (min-width: 641px) {
    width: 30vw !important;
  }

  .header {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 5px;

    .dates {
      font-size: var(--fs-xs);
    }
  }

  .content {
    width: 100%;

    a {
      color: var(--blue) !important;
    }
  }

  .footer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    .closeBtn {
      background-color: transparent !important;
      color: var(--brand-color) !important;
      border: 1px solid var(--brand-color);
      padding: 5px !important;
    }
  }
}
